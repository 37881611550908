import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Sidebar from '../../common/Sidebar';
import { SERVER_URL } from '../../lib/constants';
import './UpdateEscalationInfo.css';
import { toast, ToastContainer } from 'react-toastify';


const UpdateEscalationInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const escalationData = location.state?.escalationData || {};

    // Define formatDate function before using it in formData
    const formatDate = (date) => {
      if (!date) return '';
      const d = new Date(date);
      const month = `${d.getMonth() + 1}`.padStart(2, '0');
      const day = `${d.getDate()}`.padStart(2, '0');
      const year = d.getFullYear();
      return `${year}-${month}-${day}`;
    };
  
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;


    const [formData, setFormData] = useState({
      // sequenceID: clientData.SequenceID || '', 
      escalationDate: formatDate(escalationData.ESCALATION_DATE),
      escalationID: escalationData.ESCALATIONID,
      account: escalationData.ACCOUNT || null,
      lob: escalationData.LOB || null,
      task: escalationData.TASK || null,
      site: escalationData.SITE || null,
      oic: escalationData.OIC || null,
      oicEmail: escalationData.OIC_EMAIL || null,
      clientCategory: escalationData.CLIENTCATEGORY || null,
      escalationType: escalationData.ESCALATIONTYPE || null,
      escalationDetails: escalationData.ESCALATIONDETAILS || null,
      validity: escalationData.VALIDITY || null,
      criticality: escalationData.CRITICALITY || null,
      reportSubmitted: escalationData.REPORTSUBMITTED || null,
      reportSubmittedDate: formatDate(escalationData.REPORTSUBMITTEDDATE) || null,
      status: escalationData.STATUS || null,
      resolvedDate: formatDate(escalationData.RESOLVEDDATE) || null,
      accountCode: escalationData.ACCOUNTCODE || null,
      actionTaken: escalationData.ACTIONTAKEN || null,
      resolutionStatus: escalationData.RESOLUTIONSTATUS|| null,
      dateLastUpdated: formattedDate
    });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [oicOptions, setOicOptions] = useState([]);
  const [isEditable, setIsEditable] = useState(false);

  
    // Fetch OIC List for Dropdown
    useEffect(() => {
      const fetchOicList = async () => {
        try {
          const response = await axios.get(`${SERVER_URL}/api/oicList`);
          setOicOptions(response.data); // Store full data to access EMAIL
        } catch (error) {
          console.error('Error fetching OIC list:', error);
        }
      };
    
      fetchOicList();
    }, []);

    //Fetch and identify email based on OIC dropdown selection
    useEffect(() => {
      if (formData.oic) {
        const selectedOic = oicOptions.find((oic) => oic.EMPNAME === formData.oic);
        if (selectedOic) {
          setFormData((prevData) => ({ ...prevData, oicEmail: selectedOic.EMAIL }));
        } else {
          setFormData((prevData) => ({ ...prevData, oicEmail: '' })); // Clear if no match found
        }
      } else {
        setFormData((prevData) => ({ ...prevData, oicEmail: '' })); // Clear if OIC is deselected
      }
    }, [formData.oic, oicOptions]);


    const getMessageClass = () => {
      return error === "Update Successful" ? "success-message" : "error-message";
    };

    //To ensure all fields should be inputted
    // Validate form fields
    const validateForm = () => {
      const missingFields = [];
    
      if (formData.status === "Closed") {
        if (!formData.validity) missingFields.push("Validity");
        if (!formData.reportSubmitted) missingFields.push("Report Submitted?");
        if (!formData.resolvedDate) missingFields.push("Resolved Date");
        if (!formData.actionTaken) missingFields.push("Actions Taken");
    
        if (missingFields.length > 0) {
          setError(`Fill up all required fields (*): ${missingFields.join(", ")}`);
          return false;
        }
      }
    
      setError(`Update Successful`);
      return true;
    };


      // Handle form inputs change
      // Handle form inputs change
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        setFormData(prevData => {
          // Check if resolutionStatus is changed and not equal to "Pending"
          if (name === "resolutionStatus" && value !== "Pending") {
            return {
              ...prevData,
              [name]: value,
              status: "Closed" // Automatically set status to "Closed"
            };
          }
          
          return {
            ...prevData,
            [name]: value
          };
        });
      };


  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
    // console.log(formData)
    // return 
    axios.post(`${SERVER_URL}/updateEscalationInfo`, formData)
      .then(response => {
        toast.success(`Data for ${formData.accountCode} updated successfully.`);
        setTimeout(() => {
          navigate('/Escalations');
        }, 3000); // Match autoClose duration from ToastContainer
      })
      .catch(error => {
        toast.error('Error updating escalation information. Please try again.');
        setError('Error updating escalation information');
      });
  };
  
    // Function to handle the cancel action
    const handleCancel = () => {
      navigate('/UpdateEscalationinfo'); // Navigate to the previous page
  
    };
  
    const handleExit = () => {
      navigate('/Escalations'); // Navigate to Escalations Dashboard
  
    };
  
    const reqD1 = () => {
      if (formData.status === "Closed") {
        return "*";
      } else {
        return "";
      }
    };
  
    const reqD2 = () => {
      if (formData.reportSubmitted === "Yes") {
        return "*";
      } else {
        return "";
      }
    };

    return (
      <div className="sidebar-and-main-container">
        <Sidebar />
  
        <div className="updateEscalation-info-container">
          <h2 className="form-title">Update Escalation Information</h2>
          <div className="save-update-cancel-button-container">
        {/* Toggle Edit Mode Button */}
        {formData.status !== "Closed" && (
            <button
              type="button"
              className={isEditable ? "cancel-button" : "update-button"}
              onClick={() => {
                if (isEditable) {
                  handleCancel(); // Reset form if Cancel is clicked
                }
                setIsEditable(!isEditable);
              }}
            >
              {isEditable ? "Cancel" : "Update"}
            </button>
          )}
  
        {/* Save Button */}
        {isEditable && (
          <button type="submit" className="save-button" onClick={handleSubmit}>
            Save
          </button>)}
  
          {!isEditable && (
          <button type="submit" className="back-button" onClick={handleExit}>
            Back
          </button>
          )}
  
      </div>
          <div className="updateEscalation-form-spacer"></div>
          {error && <div className={getMessageClass()}>{error}</div>}
          <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      
        <form className="updateEscalation-info-form" >
        <div className="updateEscalation-form-column">
            {/* Column 1 */}
            <div className="updateEscalation-form-spacer"></div>
            <div className="updateEscalation-form-group">
              <label>Escalation Date <span style={{ color: 'red' }}>*</span></label>
              <input type="date" name="escalationDate" value={formData.escalationDate} onChange={handleInputChange}  disabled 
              />
            </div>
            <div className="updateEscalation-form-group">
              <label>Account <span style={{ color: 'red' }}>*</span></label>
              <textarea name="account" rows="1" className="updateEscalation-input" value={formData.account} onChange={handleInputChange} disabled></textarea>
            </div>


          </div>

          <div className="updateEscalation-form-column">
            {/* Column 2 */}
            <div className="updateEscalation-form-spacer"></div>
            <div className="updateEscalation-form-group">
              <label>Client Category <span style={{ color: 'red' }}>*</span></label>
              <input type="text" name="clientCategory" value={formData.clientCategory} onChange={handleInputChange}  disabled
              />
            </div>
            <div className="updateEscalation-form-group">
              <label>LOB <span style={{ color: 'red' }}>*</span></label>
              <textarea name="lob" rows="1" className="updateEscalation-input" value={formData.lob} onChange={handleInputChange} disabled></textarea>
            </div>
          </div>

          <div className="updateEscalation-form-column">
            {/* Column 3 */}
            <div className="updateEscalation-form-spacer"></div>
            <div className="updateEscalation-form-group">
              <label>Escalation ID</label>
              <textarea name="escalationID" rows="1" className="updateEscalation-input" value={formData.escalationID} onChange={handleInputChange} disabled></textarea>
            </div>
            <div className="updateEscalation-form-group">
              <label>Task <span style={{ color: 'red' }}>*</span></label>
              <textarea name="task" rows="1" className="updateEscalation-input" value={formData.task} onChange={handleInputChange} disabled></textarea>
            </div>
          </div>

          <div className="updateEscalation-form-column">
            {/* Column 4 */}
            <div className="updateEscalation-form-spacer"></div>
            <div className="updateEscalation-form-group"></div>
            <div className="updateEscalation-form-group">
              <label>Site <span style={{ color: 'red' }}>*</span></label>
              <textarea name="Site" rows="1" className="updateEscalation-input" value={formData.site} onChange={handleInputChange} disabled></textarea>
            </div>
          </div>
          </form>

          <form className="updateEscalation-info-formnotes" >
          <div className="updateEscalation-form-column">
          <div className="updateEscalation-form-notes">
              <label>Escalation Details <span style={{ color: 'red' }}>*</span></label>
              <textarea name="escalationDetails" rows="10" className="updateEscalation-textarea" value={formData.escalationDetails} onChange={handleInputChange} disabled></textarea>
            </div>
            </div>
          </form>

          <form className="addescalation-info-form" >
          <div className="updateEscalation-form-group">
              <label>Escalation Type <span style={{ color: 'red' }}>*</span></label>
              <input type="text" name="escalationType" value={formData.escalationType} onChange={handleInputChange}  disabled
              />
            </div>
            <div className="updateEscalation-form-group">
              <label>Criticality <span style={{ color: 'red' }}>*</span></label>
              <input type="text" name="criticality" value={formData.criticality} onChange={handleInputChange}  disabled
              />
            </div>
          <div className="addescalation-form-group">
              <label>OIC / Account Manager <span style={{ color: 'red' }}>*</span></label>
              <select name="oic" value={formData.oic} onChange={handleInputChange} disabled={!isEditable}>
                <option value="" disabled>(Select)</option>
                {oicOptions.slice().sort((a, b) => a.EMPNAME.localeCompare(b.EMPNAME)).map((oic, index) => (
                  <option key={index} value={oic.EMPNAME}>{oic.EMPNAME}</option>
                ))}
              </select>
            </div>
          </form>

          <form className="updateEscalation-info-form" >
          <div className="updateEscalation-form-group">
              <label>Validity <span style={{ color: 'red' }}>{reqD1()}</span></label>
              <select name="validity" value={formData.validity} onChange={handleInputChange} disabled={!isEditable}>
                <option value="" disabled selected>(Select)</option>
                <option value="Valid">Valid</option>
                <option value="Invalid">Invalid</option>
              </select>
            </div>
            <div className="updateEscalation-form-group">
              <label>Report Submitted ? <span style={{ color: 'red' }}>{reqD1()}</span></label>
              <select name="reportSubmitted" value={formData.reportSubmitted} onChange={handleInputChange} disabled={!isEditable}>
                <option value="" disabled selected>(Select)</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="updateEscalation-form-group">
              <label>Report Submission Date <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="date" name="reportSubmittedDate" value={formData.reportSubmittedDate} onChange={handleInputChange} disabled={!isEditable}
              />
            </div>
          </form>
          <form className="updateEscalation-info-formnotes" >
          <div className="updateEscalation-form-column">
          <div className="updateEscalation-form-notes">
              <label>Actions Taken <span style={{ color: 'red' }}>{reqD1()}</span></label>
              <textarea name="actionTaken" rows="10" className="updateEscalation-textarea" value={formData.actionTaken} onChange={handleInputChange} disabled={!isEditable}></textarea>
            </div>
            </div>
          </form>
          <form className="updateEscalation-info-form" >
          <div className="updateEscalation-form-group">
              <label>Resolution Status <span style={{ color: 'red' }}>{reqD1()}</span></label>
              <select name="resolutionStatus" value={formData.resolutionStatus} onChange={handleInputChange} disabled={!isEditable}>
                <option value="Pending" selected>Pending</option>
                <option value="Resolved">Resolved</option>
                <option value="Unresolved">Unresolved</option>
              </select>
            </div>
            <div className="updateEscalation-form-group">
              <label>Escalation Status <span style={{ color: 'red' }}>*</span></label>
              <select name="status" value={formData.status} onChange={handleInputChange} disabled={!isEditable}>
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
              </select>
            </div>
            <div className="updateEscalation-form-group">
              <label>Date Closed <span style={{ color: 'red' }}>{reqD1()}</span></label>
              <input type="date" name="resolvedDate" value={formData.resolvedDate} onChange={handleInputChange} disabled={!isEditable}
              />
            </div>
          </form>

  </div>
</div>
);
};

export default UpdateEscalationInfo;