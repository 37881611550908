import React, { useState, useEffect, useRef } from 'react'; 
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import './Dashboard.css';
import Sidebar from '../common/Sidebar.jsx'; 
import { IoMdRefresh } from "react-icons/io";
import UserProfileModal from "../Routes/Profile/ProfilePage.jsx";
import profilePlaceholderIcon from '../../assets/profile_placeholder.png';
import logoutIcon from '../../assets/logout_icon.png';
import addIcon from '../../assets/add_icon.png';
import searchIcon from '../../assets/search_symbol.png';
import downloadIcon from '../../assets/download_icon.png'; 
import reloadIcon from '../../assets/reload_icon.png'; 
import { SERVER_URL } from '../lib/constants.js';


import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const Dashboard = () => {
  const navigate = useNavigate(); 
  const [userid, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // Track the selected row for clearing
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  //Analytics
  const [activeClients, setActiveClients] = useState(0);
  const [onHoldClients, setOnHoldClients] = useState(0);
  const [onboardingClients, setOnboardingClients] = useState(0);
  const [prospectClients, setProspectClients] = useState(0);
  const [cancelledClients, setCancelledClients] = useState(0);
  const [discontinuedClients, setDiscontinuedClients] = useState(0);


  const tableRef = useRef(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userid');
    const storedUserName = localStorage.getItem('name');
    setUserId(storedUserId);
    setUserName(storedUserName);
  }, []);

  useEffect(() => {
    fetch(`${SERVER_URL}/api/clients`)
      .then(response => response.json())
      .then(data => {
        setRows(data);
        setFilteredRows(data);

        const activeStatusCount = data.filter(item => item.STATUS === 'Active').length;
        const onHoldStatusCount = data.filter(item => item.STATUS === 'On Hold').length;
        const onboardingStatusCount = data.filter(item => item.STATUS === 'Onboarding').length;
        const prospectStatusCount = data.filter(item => item.STATUS === 'Prospect Client').length;
        const cancelledStatusCount = data.filter(item => item.STATUS === 'Cancelled').length;
        const discontinuedStatusCount = data.filter(item => item.STATUS === 'Discontinued').length;

        setActiveClients(activeStatusCount)
        setOnHoldClients(onHoldStatusCount)
        setOnboardingClients(onboardingStatusCount)
        setProspectClients(prospectStatusCount)
        setCancelledClients(cancelledStatusCount)
        setDiscontinuedClients(discontinuedStatusCount)

      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleSearchQueryChange = (e) => setSearchQuery(e.target.value);

  useEffect(() => {
    const filteredData = rows.filter(row => {
      return Object.values(row).some(value =>
        value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setFilteredRows(filteredData);
  }, [searchQuery, rows]);

  const handleSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });

    const sortedData = [...filteredRows].sort((a, b) => {
      const aValue = a[columnKey];
      const bValue = b[columnKey];
      
      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setFilteredRows(sortedData);
  };

  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Clients");
    
    XLSX.writeFile(workbook, "Client_Data.xlsx");
  };

  const handleDoubleClick = (client) => {
    navigate('/updateClient', { state: { clientData: client } });
  };


  const formatDate = (date) => {
    if (!date) return '';
    const [year, month, day] = date.split('T')[0].split('-');
    return `${month}/${day}/${year}`;
  };

  const handleAddClient = () => {
    navigate('/addClient');
  };
    //User navigation
    const [isHovered, setIsHovered] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false); // Tracks if the process is ongoing
  
    const generateOTP = () => Math.floor(100000 + Math.random() * 900000); // Generate a 6-digit OTP
  
    const formatDateTime = (date) => {
      const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
      return date.toLocaleTimeString(undefined, options);
    };
  
    // Personalize Password
    const handleNavigateWithOTP = async (email) => {
      if (isProcessing) return; // Prevent navigation if already processing
    
      setIsProcessing(true); // Set loading state to true
    
      // Generate OTP and calculate date/time
      const otp = generateOTP();
      const now = new Date();
      const expiryDateTime = new Date(now.getTime() + 5 * 60 * 1000); // 5 minutes later
    
      const formattedRequestedDateTime = formatDateTime(now);
      const formattedExpiryDateTime = formatDateTime(expiryDateTime);
    
      try {
        // Send POST request to server
        const response = await fetch(`${SERVER_URL}/pwResetOTP`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            emailAddress: userid,
            otp,
            requestedDateTime: formattedRequestedDateTime,
            expiryDateTime: formattedExpiryDateTime,
          }),
        });
    
        if (!response.ok) {
          throw new Error(`Failed to send OTP: ${response.statusText}`);
        }
    
        // Proceed with navigation on success
        navigate('/OTP-SECURE', {
          state: {
            emailAddress: email,
            otp,
            requestedDateTime: formattedRequestedDateTime,
            expiryDateTime: formattedExpiryDateTime,
          },
        });
      } catch (error) {
        console.error('Error sending OTP:', error);
        alert('Failed to send OTP. Please try again.');
      } finally {
        setIsProcessing(false); // Reset loading state
      }
    };

    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!event.target.closest('.user-info-wrapper')) {
          setIsHovered(false);
        }
      };
    
      document.addEventListener('mousedown', handleClickOutside);
    
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

  return (
    <div className="sidebar-and-main-container">
      <Sidebar />

      <div className="main-content">
      <header
      className="dashboard-header"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px'
      }}
    >
      <h1 style={{ fontWeight: 'normal', fontSize: '20px', margin: '0' }}>Client Management</h1>

      <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
              className="user-info-wrapper"
              style={{ position: 'relative', display: 'inline-block' }}
            >
              <div
                className="user-info"
                style={{ display: 'flex', alignItems: 'center', marginRight: '10px', cursor: 'pointer' }}
                onClick={() => setIsHovered((prev) => !prev)} // Toggle dropdown on click
              >
                <img
                  src={profilePlaceholderIcon}
                  alt="Profile Placeholder Icon"
                  style={{ width: '25px', height: '25px', marginRight: '10px' }}
                />
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>{userName}</span>
              </div>

              {isHovered && (
                <div className="user-options">
                  {/* My Profile with Modal */}
                  <div
                    className="user-option"
                    onClick={() => setModalOpen(true)} // Opens the modal
                  >
                    My Profile
                  </div>

                  {/* Change Password Option */}
                  <div
                    className={`user-option ${isProcessing ? 'disabled' : ''}`}
                    onClick={() => !isProcessing && handleNavigateWithOTP(userid)}
                    style={{
                      pointerEvents: isProcessing ? 'none' : 'auto',
                      opacity: isProcessing ? 0.6 : 1,
                    }}
                  >
                    {isProcessing ? 'Requesting...' : 'Change Password'}
                  </div>

                  {/* User Profile Modal */}
                  <UserProfileModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
                </div>
              )}
            </div>

        <button
          className="logout-button"
          onClick={() => setIsLogoutModalVisible(true)}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px'
          }}
        >
          <img
            src={logoutIcon}
            alt="Logout Icon"
            style={{ width: '20px', height: '20px' }}
          />
        </button>
      </div>
    </header>

    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '30px', paddingTop: '20px', gap:'50px'}}>
          <div style={{ 
            height: '100px',
            width: '200px', 
            padding: '0px', 
            borderRadius: '8px', 
            textAlign: 'center',  
            gap: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: '#030bfc', marginBottom:'5px'}}>{activeClients}</h1>
            <h4>Active</h4>
          </div>
        </div>
        <div style={{ 
            height: '100px',
            width: '200px', 
            padding: '0px', 
            borderRadius: '8px', 
            textAlign: 'center',  
            gap: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: '#eb7d07', marginBottom:'5px'}}>{onHoldClients}</h1>
            <h4>On Hold</h4>
          </div>
        </div>
        <div style={{ 
            height: '100px',
            width: '200px', 
            padding: '0px', 
            borderRadius: '8px', 
            textAlign: 'center',  
            gap: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: '#05acfa', marginBottom:'5px'}}>{onboardingClients}</h1>
            <h4>Onboarding</h4>
          </div>
        </div>
        <div style={{ 
            height: '100px',
            width: '200px', 
            padding: '0px', 
            borderRadius: '8px', 
            textAlign: 'center',  
            gap: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: '#02cc3b', marginBottom:'5px'}}>{prospectClients}</h1>
            <h4>Prospect Clients</h4>
          </div>
        </div>
        <div style={{ 
            height: '100px',
            width: '200px', 
            padding: '0px', 
            borderRadius: '8px', 
            textAlign: 'center',  
            gap: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: '#de0098', marginBottom:'5px'}}>{cancelledClients}</h1>
            <h4>Cancelled</h4>
          </div>
        </div>
        <div style={{ 
            height: '100px',
            width: '200px', 
            padding: '0px', 
            borderRadius: '8px', 
            textAlign: 'center',  
            gap: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: '#ff0303', marginBottom:'5px'}}>{discontinuedClients}</h1>
            <h4>Discontinued</h4>
          </div>
        </div>
      </div>


          <div>
          <div className="search-bar-section">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchQueryChange}
            className="search-input"
            style={{
              backgroundImage: `url(${searchIcon})`,
              backgroundSize: '17px',
              backgroundPosition: '10px center',
              backgroundRepeat: 'no-repeat',
              paddingLeft: '40px',
              marginTop:'60px'

            }}
          />
        </div>

        <div className="button-section">
          <button className="btn reload-btn" onClick={()=>{window.location.reload()}}>
          <IoMdRefresh  className="reload-btnicon"/>
          </button>  
          <button className="btn add-btn" onClick={handleAddClient}>
            <img src={addIcon} alt="Add Icon" className="icon" /> 
            ADD CLIENT
          </button>
          <button className="btn download-btn" onClick={handleDownload}>
            <img src={downloadIcon} alt="Download Icon" className="icon" /> 
          </button>

        </div>
        </div>


        <div className="table-section" ref={tableRef}>
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort('EFFECTIVEDATE')}>Effective Date</th>
                <th onClick={() => handleSort('ACCOUNTCODE')}>Account Code</th>
                <th onClick={() => handleSort('ACCOUNT')}>Account</th>
                <th onClick={() => handleSort('LOB')}>Line of Business</th>
                <th onClick={() => handleSort('TASK')}>Task</th>
                <th onClick={() => handleSort('STATUS')}>Status</th>
              </tr>
            </thead>
          </table>

          <div className="table-body">
            <table>
              <tbody>
                {filteredRows.length > 0 ? (
                  filteredRows.map((row) => (
                    <tr 
                      key={row.ID || row.ACCOUNTCODE}
                      onClick={() => setSelectedRow(row)}
                      onDoubleClick={() => handleDoubleClick(row)}
                      className={selectedRow?.ID === row.ID ? 'selected-row' : ''}
                    >
                      <td>{formatDate(row.EFFECTIVEDATE)}</td>
                      <td>{row.ACCOUNTCODE}</td>
                      <td>{row.ACCOUNT}</td>
                      <td>{row.LOB}</td>
                      <td>{row.TASK}</td>
                      <td>{row.STATUS}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {isLogoutModalVisible && (
          <div className="overlay" onClick={() => setIsLogoutModalVisible(false)}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
              <h2>Confirm Logout</h2>
              <p>Are you sure you want to log out?</p>
              <button className="mdlcreate-button" onClick={() => {
                localStorage.clear()
                navigate('/login')}}>Logout</button>
              <button className="mdlclose-button" onClick={() => setIsLogoutModalVisible(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
